.input--search {
  border-color: $olive-light;
  border-right: none;
  border-style: solid;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-family: monospace;
  font-size: 1.4rem;
  outline: 0;
  margin-right: -0.4rem;
  padding: 2.5rem 2rem;
  width: 100%;

  &:not([disabled]):hover {
    border-color: $olive-light;
  }
  
  &:focus, &:focus:hover {
    border: .2rem dotted $sky;
  }

  &::placeholder {
    color: $gray-600;
  }
}