.submitButton {
  text-align: right;
  margin: 1.4vw 0 1.4vw 0;
}

.submitButton-actionButton {
  font-size: 1.4rem;
  padding-right: 0.4rem;

  &Icon {
    margin: 0 0.4rem 0 -1.2rem;
  }
}
