.home {
  padding: $nav-height 0;
  width: 100%;
  flex: 1;
}

.container h1, .container h2 {
  margin-top: 2.4vw;
  text-align: center;
}
