/*------------------------------------
  ErrorAlert Styles
------------------------------------*/

.errorAlert-header {
  font-size: 1.4rem;
  font-weight: bold;
  color: $gray-900;
  padding-bottom: 1.2rem;
}

.errorAlert-content {
  font-size: 1.4rem;
  color: $gray-900;
  list-style-type: circle;
  margin-left: 2.6rem;
  
  + .errorAlert-content {
    padding-top: .4rem;
  }
}