$white: #fff !default;
$gray-100: #f7f8f8 !default;
$gray-200: #f3f4f5 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #333333 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$green-dark: #00654e;
$green-light: #87c600;
$green-light-100: rgba($green-light, .16);
$green-dark: #00654e;
$olive: #afb42b;
$olive-light: #ddeacb;
$garnet: #872052;
$violet: #ad1aac;
$navy: #004d91;
$royal-blue-light: #d1ecff;
$royal-blue: #0079d1;
$royal-blue-gray: grayscale($royal-blue);
$sea-green: #009e96;
$sky: #02c7e5;
$turquoise: #00a6c4;
$slate-med: #37474f;

$purple: #5f2f93 !default;
$orange: #f26030 !default;
$yellow: #ffc02d !default;
$green: #509e2f !default;

$warning-red: #fabca8 !default;

$breakpoints: (
  md: 750px,
  lg: 1024px,
  xl: 1440px,
) !default;

$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$nav-height: 5.6rem !default;

$border-radius: .25rem !default;
$border-radius-lg: .35rem !default;
$border-width-sm: .1rem !default;
$border-width: .2rem !default;
$border-width-lg: .5rem !default;


$box-shadow: 0 .5rem 1rem rgba($black, .1);
$box-shadow-lg: 0 1rem 3rem rgba($black, .175);
$box-shadow-sm: 0 .125rem .25rem rgba($black, .1);

$font-family-serif: 'TiemposHeadline', Georgia, serif;
$font-family-sans-serif: Pragmatica, 'Open Sans', -apple-system, 'Segoe UI', Roboto, Arial, sans-serif;
$font-family-monospace: 'Courier', SFMono-Regular, Menlo, Monaco, Consolas, monospace !default;

$zHeader: 1000;
