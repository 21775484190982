/*------------------------------------
  Loader Styles
------------------------------------*/

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  animation: spin 1s $easeOutQuad infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
