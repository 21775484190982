.formHeader {
  text-align: center;
  margin-top: 2.8vw;
  h2 {
    margin-top: 2vw;
  }

}

.form {
  column-gap: 2.4rem;
  display: grid;
  grid-template-columns: 6fr ;
  position: relative;
}
