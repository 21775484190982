html {
  box-sizing: border-box;
  font-family: $font-family-sans-serif;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
  line-height: 1.25;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 10px;
  background-color: #fafafa;
}

*, *::before, *::after {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

#root > div:first-child {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

main {
  flex: 1 1 auto;
  display: flex;
  margin-top: 5.6rem;
  margin-bottom: 3.2rem;
  flex-direction: column;
}

form {
  width: auto;
}

hr {
  border: 0;
  outline: 0;
  height: 1px;
  background-color: lighten($sky, 45%);
}

a {
  text-decoration: none;
  color: inherit;
}
